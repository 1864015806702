import React from 'react'
import Drop from './NewDropdown'
import { Link } from 'gatsby'
import { useCookies } from 'react-cookie'

function BookButtons() {
  const [cookies, setCookie] = useCookies(['book'])

  function Book(cookie) {
    setCookie('book', cookie)
  }

  return (
    <div>
      <div className="flex justify-center py-8 bg-light-grey space-x-4">
        <div>
          <Drop header="Select Book">
            <div className="text-center">
              <p className="mb-4">
                <button
                  className={`${
                    cookies.book === 'volumes' ? 'bg-gray-500' : null
                  } focus:outline-none`}
                  onClick={() => Book('volumes')}
                >
                  Volumes of Praise for a Vanishing God
                </button>
              </p>
            </div>
            <div>
              <p className="">
                <button
                  onClick={() => Book('worship')}
                  className={`${
                    cookies.book === 'worship' ? 'bg-gray-500' : null
                  } focus:outline-none`}
                >
                  Worship and Congregational Singing
                </button>
              </p>
            </div>
          </Drop>
        </div>
        <div className="transform translate-y-2">
          <Link
            className="justify-center px-5 py-2 text-sm font-medium text-gray-700 border border-gray-500 rounded-md leading-5 hover:text-gray-500 focus:outline-none"
            to="/books/hymntunes"
          >
            Hymntunes
          </Link>
        </div>
      </div>
      <div className="flex justify-center bg-light-grey">
        {cookies.book === 'worship' ? (
          <div className="flex flex-wrap mb-8 text-xl text-center space-x-4">
            <div className="ml-4">
              <Link to="/books/worship-and-congregational-singing/chapter-13">
                Chapter 13
              </Link>
            </div>
            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-14">
                Chapter 14
              </Link>
            </div>
            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-16">
                Chapter 16
              </Link>
            </div>

            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-18">
                Chapter 18
              </Link>
            </div>

            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-19">
                Chapter 19
              </Link>
            </div>

            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-20">
                Chapter 20
              </Link>
            </div>

            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-21">
                Chapter 21
              </Link>
            </div>

            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-22">
                Chapter 22
              </Link>
            </div>

            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-23">
                Chapter 23
              </Link>
            </div>

            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-24">
                Chapter 24
              </Link>
            </div>

            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-25">
                Chapter 25
              </Link>
            </div>

            <div>
              <Link to="/books/worship-and-congregational-singing/chapter-26">
                Chapter 26
              </Link>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap text-xl text-center space-x-4">
            <div>
              <Link to="/books/volumes-of-praise-for-a-vanishing-god/chapter-5">
                Chapter 5
              </Link>
            </div>

            <div>
              <Link to="/books/volumes-of-praise-for-a-vanishing-god/chapter-6">
                Chapter 6
              </Link>
            </div>

            <div>
              <Link to="/books/volumes-of-praise-for-a-vanishing-god/chapter-7">
                Chapter 7
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BookButtons
